import React from 'react';
import styled from 'styled-components';

const LoadingText = styled.h1`
  color: #57068c; // NYU violet
  font-size: 4em; // Increase the font size
  text-align: center;
  margin-top: 50vh; // Vertically center the text
  border: 5px solid #808080; // Add a dark gray border
  border-radius: 25px; // Add curved corners
  background-color: #808080; // Add a dark gray background
  padding: 20px; // Add some padding
`;

const Loading = () => <LoadingText>Loading...</LoadingText>;

export default Loading;
