import { functions } from '../firebase';
import React, { useState, useEffect } from 'react';
import { auth, firestore } from '../firebase';
import { arrayUnion, arrayRemove } from 'firebase/firestore';
import Countdown from './Countdown';
import { collection, doc, getDocs, getDoc, query, where, updateDoc, onSnapshot } from 'firebase/firestore';
import styled from 'styled-components';

// const PageContainer = styled.div`
//   background-color: #202020;
//   min-height: 100vh;
//   color: #7E0ACB;
// `;

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #210338; // Dark gray
  color: #7E0ACB; // NYU violet
  min-height: 100vh; // Add this line
`;

// Define your styled components
const TournamentContainer = styled.div`
  border: 1px solid #7E0ACB;
  border-radius: 10px;
  padding: 10px;
  margin: 10px;
  background-color: #210338;
  color: #7E0ACB;
`;

const TournamentButton = styled.button`
  background-color: #7E0ACB;
  color: #210338;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 20px;
  cursor: pointer;
  margin: 10px;

  &:hover {
    background-color: #03a9f4;
  }
`;

const TournamentTitle = styled.h1`
  font-family: 'Georgia', serif;
  font-size: 24px;
  text-shadow: -0.5px 0 black, 0 0.5px black, 0.5px 0 black, 0 -0.5px #ffcd00;
  color: #7E0ACB;
`;


function Tournaments() {
  const [activeTournaments, setActiveTournaments] = useState([]);
  const [waitingTournaments, setWaitingTournaments] = useState([]);
  const [user, setUser] = useState(null);
  const [currentGame, setCurrentGame] = useState(null);
  const [move, setMove] = useState('');
  const [player1Name, setPlayer1Name] = useState('');
  const [player2Name, setPlayer2Name] = useState('');
  const [gameStatus, setGameStatus] = useState('');
  const [gameWinner, setGameWinner] = useState('');
  const [activeGames, setActiveGames] = useState([]);
  const [tournamentNames, setTournamentNames] = useState([]);
  
  useEffect(() => {
    if (user) {
      const userDoc = doc(firestore, 'users', user.uid);
      const unsubscribe = onSnapshot(userDoc, async (userDocSnapshot) => {
        const userData = userDocSnapshot.data();
        const newActiveGames = [];
        const newTournamentNames = [];
        for (const gameInfo of userData.activeGames) { 
          const { tournamentId, roundNumber, gameId } = gameInfo; 
          const gameDoc = doc(firestore, 'tournaments', tournamentId, 'rounds', String(roundNumber), 'games', gameId);
          const gameDocSnapshot = await getDoc(gameDoc);
          const game = { id: gameDocSnapshot.id, ...gameDocSnapshot.data() };
          newActiveGames.push(game);

  
          const tournamentDoc = doc(firestore, 'tournaments', tournamentId);
          const tournamentDocSnapshot = await getDoc(tournamentDoc);
          const tournament = tournamentDocSnapshot.data();
          newTournamentNames.push(tournament.tournamentName);
        }
        setActiveGames(newActiveGames);
        setTournamentNames(newTournamentNames);
      });
  
      return () => unsubscribe();
    }
  }, [user]);
  
  
  
  
  

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setUser(user);
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const unsubscribeActive = firestore.collection('tournaments')
      .where('status', '==', 'active')
      .onSnapshot((snapshot) => {
        const newTournaments = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        setActiveTournaments(newTournaments);
      });

    const unsubscribeWaiting = firestore.collection('tournaments')
      .where('status', '==', 'waiting')
      .onSnapshot((snapshot) => {
        const newTournaments = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        setWaitingTournaments(newTournaments);
      });

    return () => {
      unsubscribeActive();
      unsubscribeWaiting();
    };
  }, []);

  const handleJoinTournament = async (tournamentId) => {
    // Call the joinTournament cloud function
    const joinTournament = functions.httpsCallable('joinTournament');
    joinTournament({ tournamentId })
      .then(() => {
        console.log('Joined tournament successfully.');
      })
      .catch((error) => {
        console.error(`Error: ${error.message}`);
      });
  };
  

  const handleLeaveTournament = async (tournamentId) => {
    await firestore.collection('tournaments').doc(tournamentId).update({
      playerList: arrayRemove(user.uid),
    });

    setActiveTournaments(activeTournaments.map(tournament =>
      tournament.id === tournamentId
        ? { ...tournament, playerList: tournament.playerList.filter(uid => uid !== user.uid) }
        : tournament
    ));
  };

  const handleMoveSelection = async (selectedMove, gameId) => {
    setMove(selectedMove);
  
    const currentGame = activeGames.find(game => game.id === gameId);
    const data = {
      tournamentId: currentGame.tournamentId,
      roundNumber: currentGame.roundNumber,
      gameId: gameId,
      move: selectedMove
    };
  
    console.log('Data being sent:', data);
    
    const submitMove = functions.httpsCallable('submitMove');
    submitMove(data)
      .then((result) => {
        console.log(result.data.message);
      })
      .catch((error) => {
        console.error(`Error: ${error.message}`);
      });
  };
  

  

  const renderTournament = (tournament, isWaiting) => (
    <TournamentContainer key={tournament.id}>
      <TournamentTitle>{tournament.tournamentName}</TournamentTitle>
      <p>Status: {tournament.status}</p>
      <p>Game Mode: {tournament.gameMode}</p>
      <p>Max Players: {tournament.maxPlayers}</p>
      <p>Start Time: {tournament.startTime ? new Date(tournament.startTime.seconds * 1000).toLocaleString() : 'Not set'}</p>
      {tournament.startTime && <Countdown date={new Date(tournament.startTime.seconds * 1000)} />}
      {tournament.playerList.includes(user.uid) ? (
        <TournamentButton onClick={() => handleLeaveTournament(tournament.id)}>Leave</TournamentButton>
      ) : (
        isWaiting ? (
          <TournamentButton onClick={() => handleJoinTournament(tournament.id)}>Join</TournamentButton>
        ) : (
          <p>Too late to join this tournament</p>
        )
      )}
    </TournamentContainer>
  );

  return (
    
  
    <PageContainer>
      <h1>Ongoing Tournaments</h1>
      {activeTournaments.length > 0 ? (
        activeTournaments.map((tournament) => renderTournament(tournament, false))
      ) : (
        <p>No Active Tournaments</p>
      )}
  
      <h1>Tournaments Coming Soon</h1>
      {waitingTournaments.length > 0 ? (
        waitingTournaments.map((tournament) => renderTournament(tournament, true))
      ) : (
        <p>No Tournaments Coming Soon</p>
      )}
  
  {activeGames.map((game, index) => (
  <div key={game.id}>
    <h1>Current Game: {tournamentNames[index]}</h1>
    <p>Status: {game.status}</p>
    {game.status === 'completed' && <p>Winner: {game.winner}</p>}
    <p>Start Time: {new Date(game.startTime.seconds * 1000).toLocaleString()}</p>
    <p>End Time: {new Date(game.endTime.seconds * 1000).toLocaleString()}</p>
    {game.status !== 'completed' && game.gameMode === 'rockpaperscissors' && (
      <>
        <button onClick={() => handleMoveSelection('rock', game.id)}>Rock</button>
        <button onClick={() => handleMoveSelection('paper', game.id)}>Paper</button>
        <button onClick={() => handleMoveSelection('scissors', game.id)}>Scissors</button>
      </>
    )}
    {game.status !== 'completed' && game.gameMode !== 'rockpaperscissors' && (
      <button disabled>This game mode has not been set up yet.</button>
    )}
    {move && <p>You selected: {move}</p>}
  </div>
))}


    </PageContainer>
  );
  
}

export default Tournaments;
