import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { GoogleAuthProvider, auth } from '../firebase';
import styled from 'styled-components';


const HeaderContainer = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background-color: #210338;
  color: #7E0ACB;
  height: 60px;
  position: relative;
`;

const Logo = styled.h1`
  font-family: 'Georgia', serif;
  font-size: 24px;
  text-shadow: -0.5px 0 black, 0 0.5px black, 0.5px 0 black, 0 -0.5px #ffcd00;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
`;

const Nav = styled.nav`
  background-color: #210338;
  display: flex;
  justify-content: space-between;
  width: calc(50% - width of the logo / 2);
  height: 10px;
  min-height: 30px; // Increase this value
  transition: height 0.5s;

  &:hover {
    height: auto;
  }

  @media (max-width: 900px) {
    display: none;
  }

  a {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5vw; // Increase this value
    margin: 0 10px;
    color: #7E0ACB;
    text-decoration: none;
    padding: 10px 10px;

    border: 1px solid #7E0ACB;
    border-radius: 5px;
    background-color: transparent;

    &:hover {
      font-size: 40px;
      background-color: #7E0ACB;
      color: #210338;
    }
  }
`;




const SignOutButton = styled.button`
  position: absolute;
  top: 20px;
  right: 20px;
  background-color: #7E0ACB;
  color: #210338;
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
  font-size: 25px;
  cursor: pointer;

  &:hover {
    background-color: #03a9f4;
  }
`;

const Hamburger = styled.div`
  display: none;
  position: absolute;
  top: 20px;
  left: ${props => props.isOpen ? 'calc(250px - 60px)' : '20px'};
  z-index: 1000;
  background: #210338;
  padding: 10px;

  @media (max-width: 900px) {
    display: block;
  }

  div {
    width: 35px;
    height: 5px;
    background-color: #7E0ACB;
    margin: 6px 0;
  }
`;

const Dropdown = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 250px;
  background-color: rgba(33, 3, 56, 0.9);
  color: #7E0ACB;
  transform: translateX(${props => props.isOpen ? '0' : '-100%'});
  transition: transform 0.3s ease-in-out;
  z-index: 999;

  a {
    display: block;
    padding: 10px;
    color: inherit;
    text-decoration: none;
    border: 1px solid #7E0ACB;
    border-radius: 5px;

    &:hover {
      background-color: #7E0ACB;
      color: #210338;
    }
  }
`;

const Header = ({ user }) => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 900) {
        setIsOpen(false);
      }
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // const signInWithGoogle = () => {
  //   auth.signInWithPopup(GoogleAuthProvider);
  // };

  return (
    <div>
      <HeaderContainer>
        <Logo>NYU Mini Games</Logo>
        {user && (
          <>
            <SignOutButton onClick={() => auth.signOut()}>Sign Out</SignOutButton>
            <Hamburger onClick={() => setIsOpen(!isOpen)} isOpen={isOpen}>
              <div></div>
              <div></div>
              <div></div>
            </Hamburger>
          </>
        )}
      </HeaderContainer>
      {user && (
        <Nav>
          <Link to="/">Home</Link>
          <Link to="/tournaments">Tournaments</Link>
          <Link to="/raffle">Raffle</Link>
          <Link to="/minigames">Minigames</Link>
          <Link to="/friends">Friends</Link>
        </Nav>
      )}
      <Dropdown isOpen={isOpen}>
        <Link to="/">Home</Link>
        <Link to="/tournaments">Tournaments</Link>
        <Link to="/raffle">Raffle</Link>
        <Link to="/friends">Friends</Link>
      </Dropdown>
    </div>
  );
};

export default Header;
