import React from 'react';
import styled from 'styled-components';

const HomePageContainer = styled.div`
  min-height: 100vh;
  background-image: url(${process.env.PUBLIC_URL}/NYU_Torch.jpeg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  color: #57068c;
  padding: 0px;
  border: 20px solid #210338; // Increase border thickness
  border-radius: 0%; // Add rounded corners
`;




const Section = styled.section`
  background-color: #210338; // Lighter gray
  border-radius: 10px; // Rounded corners
  padding: 20px;
  margin-bottom: 20px;
`;

const ImagePlaceholder = styled.div`
  width: 100%;
  height: 200px;
  background-color: #555; // Even lighter gray
  border-radius: 10px; // Rounded corners
`;

const HomePage = () => {
  return (
    <HomePageContainer>
      {/* <Section>
        <h1>About NYU Mini Games</h1>
        <p>Placeholder for description text...</p>
        <ImagePlaceholder />
      </Section>

      <Section>
        <h1>Featured Game</h1>
        <p>Placeholder for game description...</p>
        <ImagePlaceholder />
      </Section> */}

      
    </HomePageContainer>
  );
};

export default HomePage;
