import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/functions';  // Import Firebase Functions

const firebaseConfig = {
    apiKey: "AIzaSyDsOXCJG1dofMuaB3YTQzriu1xu1iDQmuw",
    authDomain: "nyu-games.firebaseapp.com",
    projectId: "nyu-games",
    storageBucket: "nyu-games.appspot.com",
    messagingSenderId: "479858112260",
    appId: "1:479858112260:web:519094a3a524ae79d14251",
    measurementId: "G-NTT9QJHEQL"
  };

firebase.initializeApp(firebaseConfig);

export const auth = firebase.auth();
export const googleProvider = new firebase.auth.GoogleAuthProvider();
export const firestore = firebase.firestore();
export const functions = firebase.functions();  // Export Firebase Functions
export default firebase;