import React, { useState, useEffect } from 'react';
import { auth, googleProvider, firestore, functions } from '../firebase';
import styled, { keyframes } from 'styled-components';

// Add keyframes for a simple animation
const fadeIn = keyframes`
  0% { opacity: 0; }
  100% { opacity: 1; }
`;

const RaffleContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #210338; // Dark gray
  color: #7E0ACB; // NYU violet
  min-height: 100vh; // Add this line
`;

const CompletedRaffle = styled.div`
  animation: 2s ${fadeIn} ease-in;
  background-color: #7E0ACB; // NYU violet
  color: #210338; // Dark gray
  padding: 20px;
  margin: 20px;
  border-radius: 10px;
`;

const ActionButton = styled.button`
  background-color: #7E0ACB; // NYU violet
  color: #210338; // Dark gray
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 30px;
  cursor: pointer;
  margin-top: 10px;

  &:hover {
    background-color: #210338; // Dark gray
    color: #7E0ACB; // NYU violet
  }
`;

function Raffle() {
  const [tickets, setTickets] = useState(0);
  const [polls, setPolls] = useState([]);
  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const [openAnswer, setOpenAnswer] = useState('');
  const [showPoll, setShowPoll] = useState(false);
  const [currentPoll, setCurrentPoll] = useState(null);
  const [raffles, setRaffles] = useState([]); // Use an array to store all raffles

  useEffect(() => {
    const uid = auth.currentUser.uid; // get the current user's uid
    const userRef = firestore.collection('users').doc(uid);
    userRef.onSnapshot(doc => {
      const userData = doc.data();
      if (userData && userData.tickets) {
        setTickets(userData.tickets);
      } else {
        setTickets(0);
      }
    });
  }, []);
  

  useEffect(() => {
    // Load all raffles from the database
    const loadRaffles = async () => {
        const rafflesRef = firestore.collection('raffles');
        const snapshot = await rafflesRef.get();
        const loadedRaffles = await Promise.all(snapshot.docs.map(async doc => {
          const raffleData = doc.data();
          const rewards = await Promise.all(raffleData.rewards.map(async reward => {
            if (reward.type === 'polls') {
              const pollRef = firestore.collection('polls').doc(reward.id);
              const pollDoc = await pollRef.get();
              return { ...reward, poll: { id: pollDoc.id, ...pollDoc.data() } }; // Include the id here
            } else {
              return reward;
            }
          }));
          return { id: doc.id, ...raffleData, rewards };
        }));
        setRaffles(loadedRaffles);
      };
    loadRaffles();
  }, []);

  const updateUserTickets = (prompt, answer, raffleId) => {
    const updateUserTicketsFunc = functions.httpsCallable('updateUserTickets');
    const data = { uid: auth.currentUser.uid, prompt, answer, raffleId };
    console.log('Sending data to server:', data);
    return updateUserTicketsFunc(data)
    //   .then(result => {
    //     setTickets(result.data.tickets);
    //     return result;
    //   })
      .catch(error => {
        console.error("Error updating user tickets:", error);
        // Handle error gracefully, e.g., display an error message
      });
  };

  const handleReward = (reward, raffleId) => {
    if (reward.type === 'rewards' && reward.id === 'email-signup') {
      return (
        <ActionButton onClick={() => updateUserTickets('email-signup', null, raffleId)}>
          Sign Up for Newsletter (5 tickets)
        </ActionButton>
      );
    } else if (reward.type === 'polls') {
      const poll = reward.poll;
      if (poll) {
        return (
          <div>
            <ActionButton onClick={() => setShowPoll(!showPoll)}>
              Answer a Quick Poll (1 ticket)
            </ActionButton>
            {showPoll && (
              <div>
                <h2>{poll.question}</h2>
                {poll.type === 'multiple-choice' ? (
                  poll.answerOptions.map((option, index) => (
                    <div key={index}>
                      <input
                        type="radio"
                        id={`option-${index}`}
                        name={poll.id}
                        value={option}
                        onChange={() => setSelectedAnswer(option)}
                      />
                      <label htmlFor={`option-${index}`}>{option}</label>
                    </div>
                  ))
                ) : (
                  <textarea
                    value={openAnswer}
                    onChange={(e) => setOpenAnswer(e.target.value)}
                    maxLength={200}
                  />
                )}
                <ActionButton onClick={() => updateUserTickets('answer-poll', { pollId: poll.id, response: poll.type === 'open-answer' ? openAnswer : selectedAnswer }, raffleId)}>
  Submit Answer
</ActionButton>


              </div>
            )}
          </div>
        );
      }
    }
  };

  return (
    <RaffleContainer>
      <h1>You have {tickets} tickets</h1>
      {raffles.map(raffle => (
        raffle.status !== 'completed' ? (
          <div key={raffle.id}>
            <h2>{raffle.name}</h2>
            <p>{raffle.description}</p>
            {raffle.rewards.map(reward => (
              <div key={reward.id}>
                {handleReward(reward, raffle.id)}
              </div>
            ))}
          </div>
        ) : (
          <CompletedRaffle key={raffle.id}>
            <h2>{raffle.name}</h2>
            <p>{raffle.description}</p>
            <p>Winner: {raffle.winner}</p>
          </CompletedRaffle>
        )
      ))}
    </RaffleContainer>
  );
}

export default Raffle;



// const answerPoll = () => {
//     const pollId = currentPoll.pollId;
//     const type = currentPoll.type;
//     let answer;

//     if (type === 'multiple-choice' && !selectedAnswer) {
//       alert('Please select an answer before submitting.');
//       return;
//     } else if (type === 'open-answer' && !openAnswer) {
//       alert('Please enter an answer before submitting.');
//       return;
//     }

//     if (type === 'multiple-choice') {
//       answer = selectedAnswer;
//     } else if (type === 'open-answer') {
//       answer = openAnswer;
//     }

//     updateUserTickets('answer-poll', { pollId, response: answer });
//     setShowPoll(false);
//   };

//   const signUpNewsletter = () => {
//     updateUserTickets('email-signup');
//     // Add your code to sign up for the newsletter here
//   };

//   const handlePollButtonClick = () => {
//     // Find the first active poll
//     const activePoll = polls.find(poll => poll.status === 'active');
//     if (activePoll) {
//       setCurrentPoll(activePoll);
//       setShowPoll(true);
//     } else {
//       alert('No active poll found.');
//     }
//   };