import { useNavigate } from 'react-router-dom';
import React from 'react';
import styled, { keyframes } from 'styled-components';

const pulse = keyframes`
  0% { transform: scale(1); }
  50% { transform: scale(1.1); }
  100% { transform: scale(1); } 
`;

const NYUWordleButtonStyle = styled.button`
  background-color: #7E0ACB; 
  color: #210338; 
  border: none;
  border-radius: 10px; 
  padding: 15px 30px; 
  font-size: 24px;
  font-weight: bold;
  cursor: pointer;
  box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.3); 
  animation: ${pulse} 2s infinite; 

  &:hover {
    background-color: #210338; 
    color: #7E0ACB; 
  }
`;

  
  const NYUWordleButton = () => {
    const navigate = useNavigate(); // Get the navigate function

    const handleNYUWordleClick = () => {
      navigate('/nyu-wordle'); // Navigate to the NYU Wordle route
    };
  
    return (
      <NYUWordleButtonStyle onClick={handleNYUWordleClick}>NYU Wordle</NYUWordleButtonStyle>
    );
  }

export default NYUWordleButton;
