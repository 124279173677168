import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { auth, firestore } from './firebase'; // Make sure to import firestore
import SignIn from './components/SignIn';
import HomePage from './components/HomePage';
import Header from './components/Header';
import NotFound from './components/NotFound';
import Tournaments from './components/Tournaments';
import styled from 'styled-components';
import Loading from './components/Loading';
import AdminDashboard from './components/Admin/AdminDashboard';
import { createGlobalStyle } from 'styled-components';
import Raffle from './components/Raffle';
import Minigames from './components/Minigames';
import NYUWordle from './components/Minigames/NYUWordle';
import Friends from './components/Friends';

const withBackgroundImage = (Component, imageUrl) => {
  const BackgroundImageContainer = styled.div`
    min-height: 100vh;
    background-image: url(${imageUrl});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    color: #57068c; // NYU violet
    padding: 0px;
  `;

  return props => (
    <BackgroundImageContainer>
      <Component {...props} />
    </BackgroundImageContainer>
  );
};

const GlobalStyle = createGlobalStyle`
  body {
    overscroll-behavior: none;
  }
`;

const HomePageWithBg = withBackgroundImage(HomePage, `${process.env.PUBLIC_URL}/NYU_Torch.jpeg`);
const SignInWithBg = withBackgroundImage(SignIn, `${process.env.PUBLIC_URL}/NYU_Torch.jpeg`);
const LoadingWithBg = withBackgroundImage(Loading, `${process.env.PUBLIC_URL}/NYU_Torch.jpeg`);


function App() {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [displayLoading, setDisplayLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async userAuth => {
      // if (userAuth && userAuth.email.endsWith('@nyu.edu')) {
      if (userAuth) {
        const adminRef = firestore.doc(`admins/${userAuth.uid}`);
        const snapShot = await adminRef.get();
  
        const user = {
          uid: userAuth.uid,
          email: userAuth.email,
          displayName: userAuth.displayName,
          isAdmin: snapShot.exists, // The user is an admin if their uid is in the admins collection
        };
  
        setUser(user);
      } else {
        setUser(null);
      }
  
      setLoading(false);
    });
  
    return () => unsubscribe();
  }, []);
  

  useEffect(() => {
    if (!loading) {
      const timer = setTimeout(() => {
        setDisplayLoading(false);
      }, 1000);

      return () => clearTimeout(timer);
    }
  }, [loading]);

  if (displayLoading) {
    return <LoadingWithBg user={user} />;
  }

  return (
    <>
      <GlobalStyle />
      <Router>
        <Header user={user} />
        <Routes>
          <Route path="/signin" element={user ? <Navigate to="/" /> : <SignInWithBg />} />
          <Route path="/" element={user ? <HomePageWithBg /> : <Navigate to="/signin" />} />
          <Route path="/tournaments" element={user ? <Tournaments /> : <Navigate to="/signin" />} />
          <Route path="/raffle" element={user ? <Raffle /> : <Navigate to="/signin" />} />
          <Route path="/admin" element={user && user.isAdmin ? <AdminDashboard /> : <Navigate to="/signin" />} />
          <Route path="/minigames" element={user ? <Minigames /> : <Navigate to="/signin" />} />
          <Route path="/nyu-wordle/:gameId" element={<NYUWordle />} /> 
          <Route path="/friends" element={<Friends />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
