import React, { useState, useEffect } from 'react';
import { doc, onSnapshot, getDoc, collection, query, where, getDocs } from '@firebase/firestore';
import { auth, functions, firestore } from '../firebase';
import Profile from './Profile';

const Friends = () => {
  const [user, setUser] = useState(null);
  const [search, setSearch] = useState('');
  const [users, setUsers] = useState([]);
  const [sentRequests, setSentRequests] = useState([]);
  const [receivedRequests, setReceivedRequests] = useState([]);
  const [selectedFriend, setSelectedFriend] = useState(null);
  const [friends, setFriends] = useState([]);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setUser(user);
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (user) {
      const userDoc = doc(firestore, 'users', user.uid);
      const unsubscribe = onSnapshot(userDoc, async (userDocSnapshot) => {
        const userData = userDocSnapshot.data();
        const sentRequestIds = userData.sentRequests || [];
        const receivedRequestIds = userData.friendRequests || [];
        const friendIds = userData.friends || [];

        const sentRequestDocs = await Promise.all(sentRequestIds.map(id => getDoc(doc(firestore, 'users', id))));
        setSentRequests(sentRequestDocs.map(doc => ({ id: doc.id, ...doc.data() })));

        const receivedRequestDocs = await Promise.all(receivedRequestIds.map(id => getDoc(doc(firestore, 'users', id))));
        setReceivedRequests(receivedRequestDocs.map(doc => ({ id: doc.id, ...doc.data() })));

        const friendDocs = await Promise.all(friendIds.map(id => getDoc(doc(firestore, 'users', id))));
        setFriends(friendDocs.map(doc => ({ id: doc.id, ...doc.data() })));
      });
  
      return () => unsubscribe();
    }
  }, [user]);

  useEffect(() => {
    if (search !== '') {
      const searchUsers = functions.httpsCallable('searchUsers');
      searchUsers({ query: search })
        .then(result => setUsers(result.data.users))
        .catch(error => console.error("Error searching users:", error));
    }
  }, [search]);

  const handleSendFriendRequest = (friendId) => {
    const sendFriendRequest = functions.httpsCallable('sendFriendRequest');
    sendFriendRequest({ friendId })
      .then(() => console.log("Sent friend request successfully."))
      .catch(error => console.error("Error sending friend request:", error));
  };

  const handleFriendClick = (friendId) => {
    setSelectedFriend(friendId);
  };

  const handleAcceptFriendRequest = (friendId) => {
    const acceptFriendRequest = functions.httpsCallable('acceptFriendRequest');
    acceptFriendRequest({ friendId })
      .then(() => console.log("Accepted friend request successfully."))
      .catch(error => console.error("Error accepting friend request:", error));
  };

  return (
    <div>
      <input value={search} onChange={(e) => setSearch(e.target.value)} placeholder="Search for a friend" />
      {users.map((user) => (
        <div key={user.id}>
          <h2>{user.displayName}</h2>
          <p>{user.email}</p>
          <button onClick={() => handleSendFriendRequest(user.id)}>Add Friend</button>
        </div>
      ))}
      <h2>Friends</h2>
{friends.map((friend) => (
  <div key={friend.id}>
    <h2>{friend.displayName}</h2>
    <button onClick={() => handleFriendClick(friend.id)}>View Profile</button>
  </div>
))} 
      <h2>Sent Friend Requests</h2>
      {sentRequests.map((request) => (
        <div key={request.id}>
          <h2>{request.displayName}</h2>
          <p>Status: Pending</p>
          <button onClick={() => handleFriendClick(request.id)}>View Profile</button>
        </div>
      ))}
      <h2>Received Friend Requests</h2>
      {receivedRequests.map((request) => (
        <div key={request.id}>
          <h2>{request.displayName}</h2>
          <button onClick={() => handleFriendClick(request.id)}>View Profile</button>
          <button onClick={() => handleAcceptFriendRequest(request.id)}>Accept Request</button>
        </div>
      ))}
      {selectedFriend && <Profile friendId={selectedFriend} />}
    </div>
  );
};

export default Friends;
