import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import words from './words';
import { doc, getDoc } from 'firebase/firestore';
import { firestore } from '../../firebase';
import { functions } from '../../firebase';
import { useParams } from 'react-router-dom'; // Import useParams

const fadeIn = keyframes`
  0% { opacity: 0; }
  100% { opacity: 1; }
`;

const GameContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #210338;
  color: #7E0ACB;
  min-height: 100vh;
`;

const GuessBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  margin: 5px;
  border-radius: 5px;
  color: #210338;
  background-color: ${props => props.color};
`;

const CompletedGame = styled.div`
  animation: 2s ${fadeIn} ease-in;
  display: flex;
  width: fit-content;
  padding: 20px;
  margin: 20px;
  border-radius: 10px;
`;

const ActionButton = styled.button`
  background-color: #7E0ACB;
  color: #210338;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 30px;
  cursor: pointer;
  margin-top: 10px;

  &:hover {
    background-color: #210338;
    color: #7E0ACB;
  }
`;

const NYUWordle = () => {
    const { gameId } = useParams();
    const [wordLength, setWordLength] = useState(0);
    const [guess, setGuess] = useState("");
    const [results, setResults] = useState([]);
    const [round, setRound] = useState(0); // State for current round
    const [pastWinners, setPastWinners] = useState([]); // State for past round winners
    const [overallWinner, setOverallWinner] = useState(null); // State for overall winner

    useEffect(() => {
        const gamesCollection = firestore.collection('minigames').doc('NYUWordle').collection('games'); 
        const gameDoc = gamesCollection.doc(gameId);
        
        const unsubscribe = gameDoc.onSnapshot(async (gameDocSnapshot) => {
            const gameData = gameDocSnapshot.data();
        
            if (gameData) {
                if (gameData.round && gameData.wordLengths) {
                    setRound(gameData.round);
                    setWordLength(gameData.wordLengths[gameData.round - 1]);
                }
                // Fetch and display past winner displayNames
                if (gameData.winners) {
                    const winnerPromises = gameData.winners.map(async (winnerId) => {
                        const userDoc = await firestore.collection('users').doc(winnerId).get();
                        return userDoc.data()?.displayName || winnerId; // Fallback to userId
                    });
                    const winnerDisplayNames = await Promise.all(winnerPromises);
                    setPastWinners(winnerDisplayNames);
                }
        
                // Fetch and display overall winner's displayName
                if (gameData.overallWinner) {
                    const winnerDoc = await firestore.collection('users').doc(gameData.overallWinner).get();
                    const winnerDisplayName = winnerDoc.data()?.displayName || gameData.overallWinner;
                    setOverallWinner(winnerDisplayName);
                } else {
                    setOverallWinner(null);
                }
            } else {
                console.error("Error: Game data not found");
            }
        });
        
        return () => unsubscribe();
    }, [gameId]);
    
    
      
  
    const handleGuess = async () => {
      if (guess.length !== wordLength) {
        alert(`Please enter a word of length ${wordLength}`);
        return;
      }

      // Convert guess to uppercase
      const upperCaseGuess = guess.toUpperCase();
  
      // Call the checkWordleGuess Firebase Cloud Function
      const checkWordleGuess = functions.httpsCallable('checkWordleGuess');
      const result = await checkWordleGuess({ gameId, guess: upperCaseGuess });
  
      // Update the results state with the result from the Cloud Function
      setResults([...results, { guess: upperCaseGuess, result: result.data }]);
      setGuess("");
    };
  

    return (
        <GameContainer>
            <h1>NYU Wordle</h1>
    
            {/* Display round number only if the game is not over */}
            {!overallWinner && (
    <> 
        <p>Round: {round}</p>
        <p>Word Length: {wordLength}</p>  {/* Add this line */}
    </>
)} 
    
            {/* Display past winners */}
            {pastWinners.length > 0 && (
                <div>
                    <h2>Past Winners</h2>
                    <ul>
                        {pastWinners.map((winnerId, index) => (
                            <li key={index}>Round {index + 1}: {winnerId}</li>
                        ))}
                    </ul>
                </div>
            )}
    
            {/* Display overall winner if the game has ended */}
            {overallWinner ? (
                <div>
                    <h2>Game Over!</h2>
                    <p>The overall winner is: {overallWinner}</p>
                </div>
            ) : (
                <>
                    {results.map((res, index) => (
                        <CompletedGame key={index}>
                            {res.result.map((color, i) => (
                                <GuessBox color={color} key={i}>
                                    {res.guess[i]}
                                </GuessBox>
                            ))}
                        </CompletedGame>
                    ))}
                    <input 
                        value={guess} 
                        onChange={(e) => setGuess(e.target.value)} 
                        style={{ textTransform: 'uppercase' }} 
                    />
                    <ActionButton onClick={handleGuess}>Guess</ActionButton>
                </>
            )}
        </GameContainer>
    );
    };

export default NYUWordle;
