import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import NYUWordleButton from './Small_Components/NYUWordleButton';
import NYUWordle from './Minigames/NYUWordle';
import { doc, onSnapshot, getDoc } from '@firebase/firestore';
import { firestore, auth } from '../firebase';
import CreateGamePopup from './CreateGamePopup'; // Import your CreateGamePopup component
import { useNavigate } from 'react-router-dom';

const fadeIn = keyframes`
  0% { opacity: 0; transform: translateY(-10px); }
  100% { opacity: 1; transform: translateY(0); }
`;

const MinigamesContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #210338; 
  color: #7E0ACB; 
  min-height: 100vh; 
  padding: 40px;
  animation: 1s ${fadeIn} ease-in;
`;

const MinigameCard = styled.div`
  width: 300px;
  height: 200px;
  background-color: #f0f0f0; 
  margin: 20px;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease-in-out;

  &:hover {
    transform: scale(1.05); 
  }
`;

const MinigamesTitle = styled.h1`
  font-size: 48px;
  margin-bottom: 20px;
  text-shadow: -0.5px 0 #000, 0 0.5px #000, 0.5px 0 #000, 0 -0.5px #ffcd00;
`;

const GameGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Adjust for more minigames */
  gap: 20px;
`;

const Minigames = () => {
    const [user, setUser] = useState(null);
    const [activeGames, setActiveGames] = useState([]);
    const [currentGame, setCurrentGame] = useState(null);
    const [showCreateGamePopup, setShowCreateGamePopup] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
      const unsubscribe = auth.onAuthStateChanged((user) => {
        setUser(user);
      });
  
      return () => unsubscribe();
    }, []);
  
    useEffect(() => {
        if (user) {
          const userDoc = doc(firestore, 'users', user.uid);
          const unsubscribe = onSnapshot(userDoc, async (userDocSnapshot) => {
            const userData = userDocSnapshot.data();
      
            // Check if 'activeMinigames' exists and is an array
            const gamePromises = userData.activeMinigames && Array.isArray(userData.activeMinigames) ? userData.activeMinigames.map(async (gameInfo) => {
              const gameId = gameInfo.gameId;
              const gameType = gameInfo.gameType;
              const gameDoc = doc(firestore, 'minigames', gameType, 'games', gameId);
              const gameDocSnapshot = await getDoc(gameDoc);
              return { id: gameDocSnapshot.id, ...gameDocSnapshot.data() };
            }) : [];
      
            const games = await Promise.all(gamePromises);
            setActiveGames(games);
          });
      
          return () => unsubscribe();
        }
      }, [user]); 
  
    const handleGameClick = (game) => {
        navigate(`/nyu-wordle/${game.id}`); // Redirect to the game
      };
  
    const handleCreateGameClick = () => {
      setShowCreateGamePopup(true);
    };
  
    return (
        <MinigamesContainer>
          <GameGrid>
            <button onClick={handleCreateGameClick}>Create New Game</button>
            {showCreateGamePopup && <CreateGamePopup onClose={() => setShowCreateGamePopup(false)} />}
          {activeGames.map((game) => (
            <MinigameCard key={game.id}>
              <button onClick={() => handleGameClick(game)}>
                Open {game.type}
              </button>
            </MinigameCard>
          ))}
          {currentGame && currentGame.type === 'wordle' && <NYUWordle game={currentGame} />}
        </GameGrid>
      </MinigamesContainer>
    );
  };
  
  export default Minigames;