import React, { useState, useEffect } from 'react';
import { auth, firestore } from '../../firebase'; // Make sure to import Firestore
import { collection, addDoc, updateDoc, arrayUnion, arrayRemove, doc, getDocs, query, where } from 'firebase/firestore';
import { functions } from '../../firebase'

function AdminDashboard() {
  const [tournamentName, setTournamentName] = useState('');
  const [gameMode, setGameMode] = useState('');
  const [maxPlayers, setMaxPlayers] = useState('');
  const [startTime, setStartTime] = useState('');
  const [email, setEmail] = useState('');
  const [tournaments, setTournaments] = useState([]);
  const [status, setStatus] = useState('inactive'); // Add a new state variable for the status
  const [selectedTournament, setSelectedTournament] = useState(null); // New state variable for the selected tournament
  const [gameModes, setGameModes] = useState([]); // New state variable for the game modes
  const startTournament = functions.httpsCallable('startTournament');
  const [pollQuestion, setPollQuestion] = useState('');
const [pollType, setPollType] = useState('');
const [pollStatus, setPollStatus] = useState('');
const [uid, setUid] = useState(''); // Add this line
const [raffleId, setRaffleId] = useState(''); // Add this line
const [raffles, setRaffles] = useState([]); // Add this line
  const [raffleName, setRaffleName] = useState(''); // New state variable for the raffle name
  const [raffleDescription, setRaffleDescription] = useState(''); // New state variable for the raffle description
  const [raffleRewards, setRaffleRewards] = useState([]); // New state variable for the raffle rewards
  const [raffleWinner, setRaffleWinner] = useState(null); // New state variable for the raffle winner
  const [raffleEndTime, setRaffleEndTime] = useState(''); // New state variable for the raffle end time
  const [rewards, setRewards] = useState([]); // New state variable for the rewards
  const [selectedReward, setSelectedReward] = useState(''); // New state variable for the selected reward
  const [polls, setPolls] = useState([]); // New state variable for the polls
  const [selectedPoll, setSelectedPoll] = useState(''); // New state variable for the selected poll
  const [ticketCount, setTicketCount] = useState(0); // New state variable for the ticket count
  const [rewardType, setRewardType] = useState('');
  const [selectedRaffleForWinner, setSelectedRaffleForWinner] = useState('');


  // Fetch polls on mount and update with real-time changes
  useEffect(() => {
    const unsubscribe = firestore.collection('polls').onSnapshot((snapshot) => {
      setPolls(snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
    });

    return () => unsubscribe();
  }, []);

  // Function to add a reward to a raffle
  // Function to add a reward to a raffle
const addRewardToRaffle = async (raffleId, rewardId, ticketCount, type) => {
  const raffleRef = firestore.collection('raffles').doc(raffleId);
  await raffleRef.update({
    rewards: arrayUnion({ id: rewardId, tickets: ticketCount, type: type }),
  });

  console.log(`Reward ${rewardId} added to raffle ${raffleId} with ${ticketCount} tickets`);
};


  // Fetch rewards on mount and update with real-time changes
  useEffect(() => {
    const unsubscribe = firestore.collection('rewards').onSnapshot((snapshot) => {
      setRewards(snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
    });

    return () => unsubscribe();
  }, []);

  // Fetch raffles on mount and update with real-time changes
  useEffect(() => {
    const unsubscribe = firestore.collection('raffles').onSnapshot((snapshot) => {
      setRaffles(snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
    });

    return () => unsubscribe();
  }, []);

  // Function to create a new raffle
  const createRaffle = async () => {
    const rafflesRef = firestore.collection('raffles');
    const raffleRef = await rafflesRef.add({
      name: raffleName,
      description: raffleDescription,
      rewards: raffleRewards,
      winner: raffleWinner,
      endTime: raffleEndTime,
      tickets: [],
    });

    console.log(`Raffle created with ID: ${raffleRef.id}`);
  };

// Function to add a ticket to a raffle
const addTicketToRaffle = async (raffleId, uid) => {
  const raffleRef = firestore.collection('raffles').doc(raffleId);
  const ticketsRef = raffleRef.collection('tickets');
  await ticketsRef.add({ uid });

  console.log(`Ticket added for user ${uid} to raffle ${raffleId}`);
};

const handleCreatePoll = async () => {
  
  const createPoll = functions.httpsCallable('createPoll');
    try {
      const result = await createPoll({
        question: pollQuestion,
        type: pollType,
        status: pollStatus,
      });
      console.log('Poll created with ID: ', result.data.pollId);
    } catch (error) {
      console.error('Error creating poll:', error);
    }

  setPollQuestion('');
  setPollType('');
  setPollStatus('');
};

const chooseWinner = async (raffleId) => {
  console.log('Raffle ID:', raffleId); // Log the raffle ID

  if (!raffleId) {
    console.error('Raffle ID is not defined or is an empty string:', raffleId);
    return;
  }

  const raffleRef = firestore.collection('raffles').doc(raffleId);
  const ticketsRef = raffleRef.collection('tickets');
  const snapshot = await ticketsRef.get();

  if (snapshot.empty) {
    console.log('No matching documents.');
    return;
  }

  // Choose a random ticket
  const tickets = snapshot.docs;
  const winningTicket = tickets[Math.floor(Math.random() * tickets.length)];

  console.log('Winning Ticket:', winningTicket); // Log the winning ticket

  // Get the UID of the user who owns the winning ticket
  const winnerUid = winningTicket.data().uid;

  console.log('Winner UID:', winnerUid); // Log the winner's UID

  // Get the user's document
  const userRef = firestore.collection('users').doc(winnerUid);
  const userDoc = await userRef.get();
  const userData = userDoc.data();

  console.log('User Data:', userData); // Log the user's data

  // Get the user's name and profile photo URL
  const winnerName = userData.displayName; // replace 'name' with 'displayName'
  const winnerPhotoURL = userData.photoURL; // use 'photoURL' instead of 'photo'

  console.log('Winner Name:', winnerName); // Log the winner's name
  console.log('Winner Photo URL:', winnerPhotoURL); // Log the winner's photo URL

  // Update the raffle with the winner's name, photo URL, and status
  await raffleRef.update({
    winner: winnerName,
    winnerPhotoURL: winnerPhotoURL,
    status: 'completed'
  });

  console.log(`Winner chosen for raffle ${raffleId}: ${winnerName}`);
};



  // Fetch tournaments on mount and update with real-time changes
  useEffect(() => {
    const unsubscribe = firestore.collection('tournaments').onSnapshot((snapshot) => {
      setTournaments(snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
    });

    return () => unsubscribe();
  }, []);

  // useEffect(() => {
  //   const fetchTournaments = async () => {
  //     const tournamentsCollection = await firestore.collection('tournaments').get();
  //     setTournaments(tournamentsCollection.docs.map(doc => ({ id: doc.id, ...doc.data() })));
  //   };

  //   fetchTournaments();
  // }, []);

  const handleTournamentNameChange = (event) => {
    setTournamentName(event.target.value);
  };

  const handleGameModeChange = (event) => {
    setGameMode(event.target.value);
  };

  const handleMaxPlayersChange = (event) => {
    setMaxPlayers(event.target.value);
  };

  const handleStartTimeChange = (event) => {
    setStartTime(event.target.value);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleStatusChange = (event) => {
    setStatus(event.target.value);
  };

  const handleSelectTournament = (tournament) => {
    setSelectedTournament(tournament);
  };

  const handleCreateTournament = async () => {
    const createTournament = functions.httpsCallable('createTournament');
    try {
      const result = await createTournament({
        tournamentName,
        gameMode,
        maxPlayers,
        startTime,
      });
      console.log('Tournament created with ID: ', result.data.tournamentId);
    } catch (error) {
      console.error('Error creating tournament:', error);
    }
  
    setTournamentName('');
    setGameMode('');
    setMaxPlayers('');
    setStartTime('');
  };
  
  
  const handleStartTournament = async (tournamentId) => {
    try {
      await startTournament({ tournamentId });
    } catch (error) {
      console.error('Error starting tournament:', error);
    }
  };

  const handleUpdateTournament = async () => {
    // Update the selected tournament in Firestore
    await firestore.collection('tournaments').doc(selectedTournament.id).update(selectedTournament);
  };


  const handleDeleteTournament = async (tournamentId) => {
    const db = firestore;
    const tournamentRef = db.collection('tournaments').doc(tournamentId);
  
    // Fetch the tournament document
    const tournamentDoc = await tournamentRef.get();
    const tournament = tournamentDoc.data();
  
    // Fetch all rounds in the tournament
    const roundsSnapshot = await tournamentRef.collection('rounds').get();
  
    // For each round, fetch and delete all games
    roundsSnapshot.docs.forEach(async (roundDoc) => {
      const gamesSnapshot = await roundDoc.ref.collection('games').get();
      gamesSnapshot.docs.forEach(async (gameDoc) => {
        // Fetch the game document
        const game = gameDoc.data();
  
        // For each player in the game, remove the game from their activeGames list
        [game.player1, game.player2].forEach(async (playerId) => {
          if (playerId) {
            const playerRef = db.collection('users').doc(playerId);
            await playerRef.update({
              activeGames: firestore.FieldValue.arrayRemove({
                tournamentId: tournamentId,
                roundNumber: game.roundNumber,
                gameId: gameDoc.id,  // Include gameId in the object
              }),
            });
          }
        });
  
        // Delete the game document
        await gameDoc.ref.delete();
      });
  
      // Delete the round document
      await roundDoc.ref.delete();
    });
  
    // Delete the tournament document
    await tournamentRef.delete();
  };
  

  const handleMakeAdmin = async () => {
    // Get the user document associated with the entered email address
    const userSnapshot = await firestore.collection('users').where('email', '==', email).get();

    if (!userSnapshot.empty) {
      // If a user document was found, get the user's uid
      const uid = userSnapshot.docs[0].id;

      // Add a new document to the admins collection with the user's uid
      await firestore.collection('admins').doc(uid).set({});
    }

    setEmail('');
  };

  return (
    <div>
      <h1>Admin Dashboard</h1>
      <div>
      <h2>Add Reward to Raffle</h2>
<select value={raffleId} onChange={e => setRaffleId(e.target.value)}>
  <option value="">Select a raffle</option>
  {raffles.map((raffle) => (
    <option key={raffle.id} value={raffle.id}>{raffle.name}</option>
  ))}
</select>
<select value={rewardType} onChange={e => setRewardType(e.target.value)}>
  <option value="">Select a reward type</option>
  <option value="rewards">Rewards</option>
  <option value="polls">Polls</option>
</select>
{rewardType === 'rewards' && (
  <select value={selectedReward} onChange={e => setSelectedReward(e.target.value)}>
    <option value="">Select a reward</option>
    {rewards.map((reward) => (
      <option key={reward.id} value={reward.id}>{reward.name}</option>
    ))}
  </select>
)}
{rewardType === 'polls' && (
  <select value={selectedPoll} onChange={e => setSelectedPoll(e.target.value)}>
    <option value="">Select a poll</option>
    {polls.map((poll) => (
      <option key={poll.id} value={poll.id}>{poll.question}</option>
    ))}
  </select>
)}
<input
  type="number"
  value={ticketCount}
  onChange={e => setTicketCount(e.target.value)}
  placeholder="Enter ticket count"
/>

<button onClick={() => addRewardToRaffle(raffleId, rewardType === 'rewards' ? selectedReward : selectedPoll, ticketCount, rewardType)}>Add Reward</button>

        </div>
      <div>
        <h2>Create a New Raffle</h2>
        <input
          type="text"
          value={raffleName}
          onChange={e => setRaffleName(e.target.value)}
          placeholder="Enter raffle name"
        />
        <input
          type="text"
          value={raffleDescription}
          onChange={e => setRaffleDescription(e.target.value)}
          placeholder="Enter raffle description"
        />
        <input
          type="text"
          value={raffleRewards}
          onChange={e => setRaffleRewards(e.target.value.split(','))}
          placeholder="Enter raffle rewards (comma-separated)"
        />
        <input
          type="text"
          value={raffleWinner}
          onChange={e => setRaffleWinner(e.target.value)}
          placeholder="Enter raffle winner"
        />
        <input
          type="datetime-local"
          value={raffleEndTime}
          onChange={e => setRaffleEndTime(e.target.value)}
          placeholder="Enter raffle end time"
        />
        <button onClick={createRaffle}>Create Raffle</button>
      </div>

    <div>
      <h2>Add Ticket to Raffle</h2>
      <input
        type="text"
        value={uid}
        onChange={e => setUid(e.target.value)}
        placeholder="Enter user ID"
      />
      <button onClick={() => addTicketToRaffle(raffleId, uid)}>Add Ticket</button>
    </div>

    <div>
  <h2>Choose Winner for Raffle</h2>
  <select value={selectedRaffleForWinner} onChange={e => setSelectedRaffleForWinner(e.target.value)}>
    <option value="">Select a raffle</option>
    {raffles.map((raffle) => (
      <option key={raffle.id} value={raffle.id}>{raffle.name}</option>
    ))}
  </select>
  <button onClick={() => chooseWinner(selectedRaffleForWinner)}>Choose Winner</button>
</div>


    <div>
      <h2>Raffles</h2>
      {raffles.map((raffle) => (
        raffle.status !== 'completed' ? (
          <div key={raffle.id}>
            <h3>{raffle.name}</h3>
            <p>Description: {raffle.description}</p>
            <p>Winner: {raffle.winner}</p>
          </div>
        ) : null
      ))}
    </div>
    
    <div>
      
  <h2>Completed Raffles</h2>
  {raffles.map((raffle) => (
    raffle.status === 'completed' ? (
      <div key={raffle.id}>
        <h3>{raffle.name}</h3>
        <p>Description: {raffle.description}</p>
        <p>Winner: {raffle.winner}</p>
        <p>Winner's Photo: <img src={raffle.winnerPhotoURL} alt="Winner's Profile" /></p>
        <p>Total Tickets: {raffle.totalTickets}</p>
        <p>Total Users: {raffle.totalUsers}</p>
      </div>
    ) : null
  ))}
</div>
      <div>
        <h2>Create a New Tournament</h2>
        <input
          type="text"
          value={tournamentName}
          onChange={handleTournamentNameChange}
          placeholder="Enter tournament name"
        />
        <input
          type="number"
          value={maxPlayers}
          onChange={handleMaxPlayersChange}
          placeholder="Enter max players"
        />
        <input
          type="datetime-local"
          value={startTime}
          onChange={handleStartTimeChange}
          placeholder="Enter start time (optional)"
        />
        <select value={status} onChange={handleStatusChange}>
          <option value="inactive">Inactive</option>
          <option value="waiting">Waiting</option>
          <option value="active">Active</option>
          <option value="completed">Completed</option>
        </select>
        <select value={gameMode} onChange={handleGameModeChange}>
          <option value="">Select a game mode</option>
          {gameModes.map((gameMode) => (
            <option key={gameMode} value={gameMode}>{gameMode}</option>
          ))}
        </select>
        <button onClick={handleCreateTournament}>Create Tournament</button>
      </div>
  
      <div>
        <h2>Create a New Poll</h2>
        <input
          type="text"
          value={pollQuestion}
          onChange={(e) => setPollQuestion(e.target.value)}
          placeholder="Enter poll question"
        />
        <select value={pollType} onChange={(e) => setPollType(e.target.value)}>
          <option value="">Select a poll type</option>
          <option value="multiple-choice">Multiple Choice</option>
          <option value="open-answer">Open Answer</option>
        </select>
        <select value={pollStatus} onChange={(e) => setPollStatus(e.target.value)}>
          <option value="inactive">Inactive</option>
          <option value="active">Active</option>
        </select>
        <button onClick={handleCreatePoll}>Create Poll</button>
      </div>
  
      <div>
        <h2>Start Tournament</h2>
        {tournaments.map((tournament) => (
          <div key={tournament.id}>
            <h3>{tournament.tournamentName}</h3>
            <p>Status: {tournament.status}</p> {/* Display the current status */}
            <button onClick={() => handleSelectTournament(tournament)}>Select Tournament</button>
            <button onClick={() => handleStartTournament(tournament.id)}>Start Tournament</button>
            <button onClick={() => handleDeleteTournament(tournament.id)}>Delete Tournament</button>
          </div>
        ))}
      </div>
  
      {selectedTournament && (
        <div>
          <h2>Edit Tournament</h2>
          <input
            type="text"
            value={selectedTournament.tournamentName}
            onChange={(event) => setSelectedTournament({ ...selectedTournament, tournamentName: event.target.value })}
            placeholder="Enter tournament name"
          />
          <input
            type="number"
            value={selectedTournament.maxPlayers}
            onChange={(event) => setSelectedTournament({ ...selectedTournament, maxPlayers: event.target.value })}
            placeholder="Enter max players"
          />
          <input
            type="datetime-local"
            value={selectedTournament.startTime}
            onChange={(event) => setSelectedTournament({ ...selectedTournament, startTime: event.target.value })}
            placeholder="Enter start time (optional)"
          />
          <select value={selectedTournament.status} onChange={(event) => setSelectedTournament({ ...selectedTournament, status: event.target.value })}>
            <option value="inactive">Inactive</option>
            <option value="waiting">Waiting</option>
            <option value="active">Active</option>
            <option value="completed">Completed</option>
          </select>
          <select value={gameMode} onChange={handleGameModeChange}>
            {gameModes.map((gameMode) => (
              <option key={gameMode} value={gameMode}>{gameMode}</option>
            ))}
          </select>
          <button onClick={handleUpdateTournament}>Update Tournament</button>
        </div>
      )}
  
      <div>
        <h2>Make User Admin</h2>
        <input
          type="text"
          value={email}
          onChange={handleEmailChange}
          placeholder="Enter user's email"
        />
        <button onClick={handleMakeAdmin}>Make Admin</button>
      </div><button onClick={chooseWinner}>Choose Winner</button>
    </div>
  );
  
}

export default AdminDashboard;
