import React, { useState, useEffect } from 'react';
import { doc, onSnapshot, getDoc } from '@firebase/firestore';
import { auth, functions, firestore } from '../firebase';

const Profile = ({ friendId }) => {
  const [friend, setFriend] = useState(null);

  useEffect(() => {
    const friendDoc = doc(firestore, 'users', friendId);
    const unsubscribe = onSnapshot(friendDoc, (friendDocSnapshot) => {
      const friendData = friendDocSnapshot.data();
      setFriend(friendData);
    });
  
    return () => unsubscribe();
  }, [friendId]);

  const handleRemoveFriend = () => {
    const removeFriend = functions.httpsCallable('removeFriend');
    removeFriend({ friendId })
      .then(() => console.log("Removed friend successfully."))
      .catch(error => console.error("Error removing friend:", error));
  };

  const handleChallenge = () => {
    // Call your Firebase function to challenge the friend to a game of Wordle
    // This is just a placeholder, replace it with your actual function call
    // yourFirebaseFunction(friendId);
  };

  return (
    friend ? (
      <div>
        <img src={friend.photoURL} alt="Profile" />
        <h2>{friend.displayName}</h2>
        <p>{friend.email}</p>
        <button onClick={handleRemoveFriend}>Remove Friend</button>
        <button onClick={handleChallenge}>Challenge to Wordle</button>
      </div>
    ) : (
      <p>Loading...</p>
    )
  );
};

export default Profile;
