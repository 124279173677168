import React, { useState, useEffect } from 'react';
import firebase, { auth, googleProvider, firestore, functions } from '../firebase';
import styled, { keyframes } from 'styled-components';

const SignInBox = styled.div`
  display: flex;
  flex-direction: column; // Add this line
  justify-content: center;
  align-items: center;
  width: 400px; // Adjust as needed
  height: 200px; // Adjust as needed
  border-radius: 20px; // Rounded corners
  background-color: #210338; // Dark gray
`;

const GoogleSignInButton = styled.button`
  background-color: #7E0ACB; // NYU violet
  color: #210338; // Dark gray
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 30px;
  cursor: pointer;

  img {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }

  &:hover {
    background-color: #210338; // Dark gray
    color: #7E0ACB; // NYU violet
  }
`;

const CenterContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; // This makes it fill the full screen height
`;

const flicker = keyframes`
  0% { opacity: 1; }
  50% { opacity: 0; }
  100% { opacity: 1; }
`;

const UserCount = styled.span`
  display: inline-block;
  color: #7E0ACB; // NYU violet
`;


const UserCountContainer = styled.div`
  color: #B590CA; // Lighter violet
  margin-bottom: 20px;
`;

function SignIn() {
  const [userCount, setUserCount] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getUserCount = functions.httpsCallable('getUserCount'); 
    getUserCount()
      .then(result => {
        setUserCount(result.data.count); 
        setLoading(false);
      })
      .catch(error => {
        console.error("Error getting user count:", error);
      });
  }, []);
  

  const signInWithGoogle = () => {
    firebase.auth().signInWithPopup(googleProvider)
      .then((result) => {
        const token = result.credential.accessToken;
        const user = result.user;

        const createUser = firebase.functions().httpsCallable('createUser');
        createUser({
          displayName: user.displayName,
          email: user.email,
          photoURL: user.photoURL
        })
        .then(() => {
          console.log('User created successfully');
        })
        .catch((error) => {
          console.error('Error creating user:', error);
        });
      })
      .catch((error) => {
        console.error('Error during Google Sign-In:', error);
        alert("Error during sign in. Are you using an NYU email? If you are, please email peter.perry@nyu.edu")
      });
  };

  
  return (
    <CenterContainer>
      <SignInBox>
        <UserCountContainer>
          Currently serving {loading ? '...' : <UserCount>{userCount}</UserCount>} NYU community members
        </UserCountContainer>
        <GoogleSignInButton onClick={signInWithGoogle}>
          <img src={"Google_Icon.png"} alt="Google logo" />
          Sign In with Google
        </GoogleSignInButton>
      </SignInBox>
    </CenterContainer>
  );
}

export default SignIn;



  // const signInWithGoogle = () => {
  //   auth.signInWithPopup(googleProvider)
  //     .then((result) => {
  //       var user = result.user;
  
  //       return firestore.collection('users').doc(user.uid).set({
  //         displayName: user.displayName,
  //         email: user.email,
  //         photoURL: user.photoURL,
  //         activeGames: []
  //       }, { merge: true });
  //     })
  //     .catch((error) => {
  //       // alert('Sign in failed. Only NYU email addresses are allowed.');
  //     });
  // };