import React, { useState, useEffect } from 'react';
import { doc, onSnapshot, getDocs, getDoc, collection} from 'firebase/firestore';
import { auth, functions, firestore } from '../firebase';
import styled, { keyframes } from 'styled-components';



const fadeIn = keyframes`
  0% { opacity: 0; }
  100% { opacity: 1; }
`;

const PopupContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #210338;
  color: #7E0ACB;
  min-height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const PopupContent = styled.div`
  animation: 2s ${fadeIn} ease-in;
  background-color: #7E0ACB;
  color: #210338;
  padding: 20px;
  margin: 20px;
  border-radius: 10px;
`;

const ActionButton = styled.button`
  background-color: #7E0ACB;
  color: #210338;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 30px;
  cursor: pointer;
  margin-top: 10px;

  &:hover {
    background-color: #210338;
    color: #7E0ACB;
  }
`;

const CreateGamePopup = ({ onClose }) => {
  const [playerLimit, setPlayerLimit] = useState(2);
  const [invites, setInvites] = useState([]);
  const [gameMode, setGameMode] = useState('wordle');
  const [friends, setFriends] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchFriends = async () => {
      try {
        const uid = auth.currentUser.uid;
        const userDoc = doc(firestore, 'users', uid);
        const userSnapshot = await getDoc(userDoc);
        const friendsIds = userSnapshot.data().friends;
        const friendsData = await Promise.all(friendsIds.map(async friendId => {
          const friendSnapshot = await getDoc(doc(firestore, 'users', friendId));
          return { id: friendId, ...friendSnapshot.data() };
        }));
        setFriends(friendsData);
        setIsLoading(false); // Update loading state only if successful
      } catch (error) {
        console.error("Error fetching friends:", error);
      }
    };
    fetchFriends();
  }, []);

  const handleCreateGame = () => {
    const uid = auth.currentUser.uid; // Get the UID of the current user
    const allInvites = [...invites, uid]; // Add the current user's UID to the invites array
  
    const createGame = functions.httpsCallable('createGame');
    createGame({ playerLimit, invites: allInvites, gameMode }) // Pass allInvites instead of invites
      .then(result => {
        console.log('Game created with ID: ', result.data.gameId);
      })
      .catch(error => {
        console.error('Error creating game: ', error);
      });
    onClose();
  };
  

  const handleInviteChange = (friendId) => {
    const isChecked = invites.includes(friendId); // Check if friend is already invited

    if (isChecked) {
      setInvites(invites.filter(id => id !== friendId));  // Remove if unchecked
    } else {
      setInvites([...invites, friendId]); // Add if checked
    }
    console.log("Invites:", invites); 

  };

  return (
    <PopupContainer>
      <PopupContent>
        <h2>Create a New Game</h2>
        <label>
          Game Mode:
          <select value={gameMode} onChange={(e) => setGameMode(e.target.value)}>
            <option value="wordle">Wordle</option>
            {/* Add more game modes here */}
          </select>
        </label>
        <label>
          Player Limit:
          <input type="number" value={playerLimit} onChange={(e) => setPlayerLimit(e.target.value)} />
        </label>
        <h2>Invite Friends:</h2>
        {isLoading ? ( 
          <p>Loading Friends...</p>
        ) : (friends.map((friend) => (
          <label key={friend.id}>
            <input 
              type="checkbox" 
              value={friend.id} 
              checked={invites.includes(friend.id)} // Check if included in 'invites'
              onChange={() => handleInviteChange(friend.id)} 
            />
            {friend.displayName}
          </label>
        ))
        )}
        <ActionButton onClick={handleCreateGame}>Create Game</ActionButton>
      </PopupContent>
    </PopupContainer>
  );
};

export default CreateGamePopup;
